<template>
  <div>
    <q-card v-if="profile">
      <q-card-section>
        <div class="row">
          <div class="col-sm-12 col-md-5 col-lg-4 q-mr-md">
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-2">
                <span>First Name</span>
              </div>
              <div class="col">
                <span>: {{ profile.firstName }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-2">
                <span>Last Name</span>
              </div>
              <div class="col">
                <span>: {{ profile.lastName }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-2">
                <span>Email</span>
              </div>
              <div class="col">
                <span>: {{ profile.email }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-2">
                <span>Username</span>
              </div>
              <div class="col">
                <span>: {{ profile.username ? profile.username : '-' }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-2">
                <span>API Url</span>
              </div>
              <div class="col">
                <span>
                  : <a :href="$apiProfile(profile.username ? profile.username : profile.id)" target="_blank">
                    {{ $apiProfile(profile.username ? profile.username : profile.id) }}
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-5 col-lg-4">
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-2">
                <span>Address</span>
              </div>
              <div class="col">
                <span>: {{ profile.address ? profile.address : '-' }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-2">
                <span>Bio</span>
              </div>
              <div class="col">
                <span>
                  <component :is="{
                    template: `: ${profile.bio}`
                  }" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'

export default {
  setup () {
    const store = useStore()
    const profile = computed(() => store.state.profile)

    return {
      profile
    }
  }
}
</script>
